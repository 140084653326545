.sites-nav {
  position: fixed;
  z-index: var(--z-index-navbar);
  top: 0;
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--neutral10);
  font-size: var(--size6);
  height: var(--sitenav-height);
}

.sites-nav a,
.sites-nav span {
  color: inherit;
  padding: 0.5rem;
}

.sites-nav a:hover {
  background-color: var(--neutral15);
}
