/**
 * Fonts
 */

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-v10-latin-regular.eot');
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
       url('../fonts/fira-sans-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fira-sans-v10-latin-regular.woff2') format('woff2'),
       url('../fonts/fira-sans-v10-latin-regular.woff') format('woff'),
       url('../fonts/fira-sans-v10-latin-regular.ttf') format('truetype'),
       url('../fonts/fira-sans-v10-latin-regular.svg#FiraSans') format('svg');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/fira-sans-v10-latin-italic.eot');
  src: local('Fira Sans Italic'), local('FiraSans-Italic'),
       url('../fonts/fira-sans-v10-latin-italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fira-sans-v10-latin-italic.woff2') format('woff2'),
       url('../fonts/fira-sans-v10-latin-italic.woff') format('woff'),
       url('../fonts/fira-sans-v10-latin-italic.ttf') format('truetype'),
       url('../fonts/fira-sans-v10-latin-italic.svg#FiraSans') format('svg');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-sans-v10-latin-700.eot');
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
       url('../fonts/fira-sans-v10-latin-700.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fira-sans-v10-latin-700.woff2') format('woff2'),
       url('../fonts/fira-sans-v10-latin-700.woff') format('woff'),
       url('../fonts/fira-sans-v10-latin-700.ttf') format('truetype'),
       url('../fonts/fira-sans-v10-latin-700.svg#FiraSans') format('svg');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/fira-sans-v10-latin-700italic.eot');
  src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
       url('../fonts/fira-sans-v10-latin-700italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fira-sans-v10-latin-700italic.woff2') format('woff2'),
       url('../fonts/fira-sans-v10-latin-700italic.woff') format('woff'),
       url('../fonts/fira-sans-v10-latin-700italic.ttf') format('truetype'),
       url('../fonts/fira-sans-v10-latin-700italic.svg#FiraSans') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v14-latin-regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v14-latin-regular.woff2') format('woff2'),
       url('../fonts/montserrat-v14-latin-regular.woff') format('woff'),
       url('../fonts/montserrat-v14-latin-regular.ttf') format('truetype'),
       url('../fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v14-latin-500.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v14-latin-500.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v14-latin-500.woff2') format('woff2'),
       url('../fonts/montserrat-v14-latin-500.woff') format('woff'),
       url('../fonts/montserrat-v14-latin-500.ttf') format('truetype'),
       url('../fonts/montserrat-v14-latin-500.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v14-latin-600.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../fonts/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v14-latin-600.woff2') format('woff2'),
       url('../fonts/montserrat-v14-latin-600.woff') format('woff'),
       url('../fonts/montserrat-v14-latin-600.ttf') format('truetype'),
       url('../fonts/montserrat-v14-latin-600.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v14-latin-700.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v14-latin-700.woff2') format('woff2'),
       url('../fonts/montserrat-v14-latin-700.woff') format('woff'),
       url('../fonts/montserrat-v14-latin-700.ttf') format('truetype'),
       url('../fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg');
}
