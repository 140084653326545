body {
  padding-top: var(--body-top);
}

.navbar {
  background: var(--navbar-background);
  color: var(--navbar-font-color);
  height: var(--navbar-height);
  position: fixed;
  top: 30px;
  width: 100%;
  word-wrap: break-word;
  z-index: var(--z-index-navbar);
  /* flex-wrap: wrap; */
  font-size: var(--size5);
  border-bottom: 1px solid var(--neutral10);
  font-family: var(--alt-font);
}

.navbar a {
  text-decoration: none;
}

.navbar-brand .navbar-item:first-child,
.navbar-brand .navbar-item:first-child a {
  color: var(--navbar-font-color);
  font-size: calc(22 / var(--rem-base) * 1rem);
}

.navbar-brand .separator {
  padding: 0 0.375rem;
}

.navbar-end > .navbar-item,
.navbar-end .navbar-link {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-item:hover > a {
  font-weight: var(--body-font-weight-bold);
}

.navbar-item.has-dropdown:hover {
  box-shadow: inset 0 -2px 0 0 var(--blue20);
}

@media screen and (min-width: 1100px) {
  .navbar-end > .navbar-item,
  .navbar-end .navbar-link {
    color: var(--navbar-font-color);
  }

  .navbar-end > a.navbar-item:hover,
  .navbar-end .navbar-link:hover {
    background: var(--navbar_hover-background);
    color: var(--navbar-font-color);
  }

  .navbar-end .navbar-link::after {
    border-color: var(--navbar-font-color);
  }

  .navbar-item.has-dropdown:hover .navbar-link {
    background: var(--navbar_hover-background);
    color: var(--navbar-font-color);
  }
}

.navbar-brand {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  padding-left: 1rem;
  height: 100%;
}

.navbar-burger {
  color: var(--navbar-font-color);
  background: none;
  border: none;
  outline: none;
  line-height: 1;
  position: relative;
  /* width: var(--navbar-height); */
  width: 70px;
  padding: 20px;
  margin-left: auto;
}

.navbar-burger span {
  background: var(--navbar-font-color);
  display: block;
  height: 1px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  top: 50%;
  width: 15px;
}

.navbar-burger span:nth-child(1) {
  margin-top: -6px;
}

.navbar-burger span:nth-child(2) {
  margin-top: -1px;
}

.navbar-burger span:nth-child(3) {
  margin-top: 4px;
}

.navbar-burger.is-active span:nth-child(1) {
  margin-left: -5px;
  transform: rotate(45deg);
  transform-origin: left top;
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  margin-left: -5px;
  transform: rotate(-45deg);
  transform-origin: left bottom;
}

.navbar-menu {
  display: none;
  text-transform: uppercase;
}

.navbar-item,
.navbar-link {
  color: var(--navbar-menu-font-color);
  display: block;
  line-height: var(--doc-line-height);
  padding: 0.5rem 1.2rem;
  position: relative;
}

.navbar-item {
  flex: none;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item .icon {
  width: 1.1rem;
  height: 1.1rem;
  display: block;
}

.navbar-link {
  padding-right: 2.5em;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-mid {
  font-weight: 600;
}

.navbar-divider {
  background-color: var(--navbar-menu-border-color);
  border: none;
  height: 1px;
  margin: 0.25rem 0;
}

@media screen and (max-width: 989px) {
  .navbar-brand .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-menu {
    background: var(--navbar-menu-background);
    padding: 0.5rem 0;
  }

  .navbar-menu.is-active {
    display: block;
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    max-height: var(--nav-height);
    overflow-y: auto;
  }

  .navbar-menu a.navbar-item:hover,
  .navbar-menu .navbar-link:hover {
    background-color: var(--navbar-menu_hover-background);
  }
}

@media screen and (min-width: 990px) {
  .navbar,
  .navbar-menu,
  .navbar-mid,
  .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-link::after {
    border-width: 0 0 1px 1px;
    border-style: solid;
    content: " ";
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
  }

  .navbar-menu {
    flex-grow: 1;
  }

  .navbar-mid {
    flex: 1 1 auto;
    justify-content: center;
  }

  .navbar-brand {
    /* padding-left: 1rem; */
  }

  .navbar-end {
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .navbar-dropdown {
    background-color: var(--navbar-menu-background);
    border: 1px solid var(--navbar-menu-border-color);
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    display: none;
    top: 100%;
    left: 0;
    min-width: 100%;
    position: absolute;
    box-shadow: 0 2px 4px 2px var(--neutral10);
    padding: 1rem 0;
    text-transform: none;
  }

  .navbar-dropdown .navbar-item {
    padding: 0.5rem 1rem;
    padding-left: 2rem;
    white-space: nowrap;
  }

  .navbar-dropdown .navbar-item:last-child {
    border-radius: inherit;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-dropdown a.navbar-item:hover {
    background-color: var(--navbar-menu_hover-background);
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .navbar-brand .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar {
    align-items: center;
  }

  .navbar-mid {
    flex-wrap: wrap;
  }

  .navbar-item {
    flex: 1 1 33.33%;
    text-align: center;
    display: inline-block;
  }

  .navbar-menu {
    align-items: center;
  }
}

.navbar .button {
  display: inline-flex;
  align-items: center;
  background: var(--navbar-button-background);
  border: 1px solid var(--navbar-button-border-color);
  border-radius: 0.15rem;
  color: var(--navbar-button-font-color);
  white-space: nowrap;
  font-weight: 400;
  font-size: var(--size5);
  padding: 15px 20px;
  line-height: 1.286;
}

.navbar .button:hover,
.navbar .button:focus {
  background-color: var(--blue25);
}
