.swiper-horizontal {
    @extend .wrapper;
    position: relative;
    // transform: translateY(-50%);
}
.swiper-horizontal .swiper_item_inner .swiper_item_icon {
    display: inline-block;
    width: auto;
    img {
        width: auto;
        display: inline-block;
    }
}
.swiper_item_inner {
    text-align: center;
}
.swiper_item_icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $baseSpacing;
    + .swiper_item_text {
        display: inline-block;
        vertical-align: middle;
    }
}
.swiper_item_text {
    text-align: left;
    img .security{
        width: auto;
        display: inline-block;
    }
}
